(function(e) {
  var t = {};

  function n(o) {
    if (t[o]) {
      return t[o].exports
    }
    var i = t[o] = {
      i: o,
      l: false,
      exports: {}
    };
    e[o].call(i.exports, i, i.exports, n);
    i.l = true;
    return i.exports
  }
  n.m = e;
  n.c = t;
  n.d = function(e, t, o) {
    if (!n.o(e, t)) {
      Object.defineProperty(e, t, {
        enumerable: true,
        get: o
      })
    }
  };
  n.r = function(e) {
    if (typeof Symbol !== "undefined" && Symbol.toStringTag) {
      Object.defineProperty(e, Symbol.toStringTag, {
        value: "Module"
      })
    }
    Object.defineProperty(e, "__esModule", {
      value: true
    })
  };
  n.t = function(e, t) {
    if (t & 1) e = n(e);
    if (t & 8) return e;
    if (t & 4 && typeof e === "object" && e && e.__esModule) return e;
    var o = Object.create(null);
    n.r(o);
    Object.defineProperty(o, "default", {
      enumerable: true,
      value: e
    });
    if (t & 2 && typeof e != "string")
      for (var i in e) n.d(o, i, function(t) {
        return e[t]
      }.bind(null, i));
    return o
  };
  n.n = function(e) {
    var t = e && e.__esModule ? function t() {
      return e["default"]
    } : function t() {
      return e
    };
    n.d(t, "a", t);
    return t
  };
  n.o = function(e, t) {
    return Object.prototype.hasOwnProperty.call(e, t)
  };
  n.p = ".";
  return n(n.s = 0)
})({
  "./src/fullWidth.js": function(e, t) {
    (function() {
      if (typeof window.vceResetFullWidthElements !== "undefined") {
        return
      }
      let e = undefined;
      const t = '[data-vcv-layout-zone="header"]';
      const n = '[data-vcv-layout-zone="footer"]';
      const o = ".vcv-editor-theme-hf";

      function i() {
        e = Array.prototype.slice.call(document.querySelectorAll('[data-vce-full-width="true"],[data-vce-full-width-section="true"]'));
        if (e.length) {
          l()
        }
      }

      function l() {
        if (!e.length) {
          return
        }
        e.forEach(function(e) {
          const i = document.body;
          const l = e.parentElement;
          const r = e.querySelector('[data-vce-element-content="true"]');
          const u = document.querySelector(".vce-full-width-custom-container");
          const c = parseInt(window.getComputedStyle(e, null)["margin-left"], 10);
          const d = parseInt(window.getComputedStyle(e, null)["margin-right"], 10);
          let s, f;
          if (e.closest(t) || e.closest(n) || e.closest(o)) {
            return
          }
          if (document.body.contains(u)) {
            s = 0 - l.getBoundingClientRect().left - c + u.getBoundingClientRect().left;
            f = u.getBoundingClientRect().width
          } else {
            s = 0 - l.getBoundingClientRect().left - c;
            f = document.documentElement.getBoundingClientRect().width
          }
          e.style.width = f + "px";
          if (i.classList.contains("rtl")) {
            e.style.right = s + "px"
          } else {
            e.style.left = s + "px"
          }
          if (!e.getAttribute("data-vce-stretch-content") && !e.getAttribute("data-vce-section-stretch-content")) {
            let e = -1 * s;
            if (e < 0) {
              e = 0
            }
            let t = f - e - l.getBoundingClientRect().width + c + d;
            if (t < 0) {
              t = 0
            }
            r.style["padding-left"] = e + "px";
            r.style["padding-right"] = t + "px"
          } else {
            r.style["padding-left"] = "";
            r.style["padding-right"] = ""
          }
        })
      }
      i();
      window.addEventListener("resize", l);
      window.vceResetFullWidthElements = i;
      window.onload = function() {
        window.vceResetFullWidthElements && window.vceResetFullWidthElements()
      };
    })()
  },
  0: function(e, t, n) {
    e.exports = n("./src/fullWidth.js")
  }
});
